<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->
      <div id="kt_content_container" class="container">
        <!--begin::Basic info-->
        <div class="card mb-5 mb-xl-10">
          <!--begin::Card header-->
          <div
            class="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <!--begin::Card title-->
            <div class="card-title m-0">
              <h3 class="fw-bolder m-0">เพิ่มสินค้า</h3>
            </div>
            <!--end::Card title-->
          </div>
          <!--begin::Card header-->

          <!--begin::Content-->
          <div id="kt_account_profile_details" class="collapse show">
            <!--begin::Form-->
            <Form
              @submit="onSubmitProduct"
              :validation-schema="product"
              id="kt_account_profile_details_form"
              class="form"
              novalidate="novalidate"
            >
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->

                <BaseSelect
                  required="required"
                  label="ร้าน"
                  name="store_id"
                  type="number"
                  :data="entrepreneur_stores"
                />

                <BaseSelect
                  required="required"
                  label="ประเภทสินค้า"
                  name="product_type_id"
                  type="number"
                  :data="entrepreneur_product_types"
                />

                <BaseInput
                  label="ชื่อสินค้า"
                  required="required"
                  name="name"
                  type="text"
                />

                <BaseImage
                  label="รูปหน้าแรก"
                  required="required"
                  name="image"
                  :aspectRatio="1 / 1"
                  :height="800"
                  :width="800"
                />

                <BaseInput
                  required="required"
                  label="รายละเอียดสินค้า"
                  as="textarea"
                  name="detail"
                />

                <BaseInput
                  label="ราคา"
                  required="required"
                  type="number"
                  name="price"
                />

                <BaseInput
                  label="ขนาดสินค้า"
                  required="required"
                  name="size"
                  type="text"
                />

                <BaseInput
                  label="น้ำหนักสินค้า"
                  required="required"
                  type="number"
                  name="weight"
                />
              </div>
              <!--end::Card body-->

              <!--begin::Actions-->
              <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"> ยืนยัน </span>
                  <span class="indicator-progress">
                    กรุณารอสักครู่ ...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
              </div>
              <!--end::Actions-->
            </Form>
            <!--end::Form-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Basic info-->
      </div>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/modals/BaseImage.vue";
import BaseInput from "@/components/modals/BaseInput.vue";
import BaseSelect from "@/components/modals/BaseSelect.vue";
import { defineComponent, onMounted, ref } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import useSweetalert from "@/core/helpers/sweetalert2";
import useProduct from "@/core/services/api/travel/entrepreneur/product";
import { checkPage } from "@/core/helpers/checkpage";
import "vue-advanced-cropper/dist/style.css";
export default defineComponent({
  name: "entrepreneur-product-type-create",
  components: {
    Form,
    BaseInput,
    BaseSelect,
    BaseImage,
  },
  setup() {
    const { stoerProduct, createProduct } = useProduct();
    const { Sconfirm, SToast, SconfirmNc } = useSweetalert();
    const submitButton = ref(null);
    const entrepreneur_stores = ref([]);
    const entrepreneur_product_types = ref([]);
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    const product = Yup.object().shape({
      store_id: Yup.number().required("กรุณาเลือก ร้าน").typeError("กรุณากรอก ราคา"),
      product_type_id: Yup.number().required("กรุณาเลือก ประเภทสินค้า").typeError("กรุณากรอก ราคา"),
      name: Yup.string().required("กรุณากรอก ชื่อสินค้า"),
      image: Yup.string().required("กรุณาเลือก รูปภาพ"),
      detail: Yup.string().required("กรุณากรอก รายละเอียดสินค้า"),
      price: Yup.number().required("กรุณากรอก ราคา").typeError("กรุณากรอก ราคา"),
      size: Yup.string().required("กรุณากรอก ขนาดสินค้า"),
      weight: Yup.number().required("กรุณากรอก น้ำหนักสินค้า").typeError("กรุณากรอก ราคา"),
    });

    const onSubmitProduct = (values) => {
      Sconfirm("ยืนยัน การเพิ่มสินค้า", "question").then(
        async (result) => {
          if (result.isConfirmed) {
            if (submitButton.value) {
              // Activate indicator
              submitButton.value.setAttribute("data-kt-indicator", "on");
              submitButton.value.setAttribute("disabled", "disabled");
            }
            await stoerProduct(values)
              .then(() => {
                SToast("success", "เพิ่ม สินค้า สำเร็จ");
              })
              .catch(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                submitButton.value?.removeAttribute("disabled");
              SconfirmNc(
                "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
                "error",
                "ลองอีกครั้ง !"
              );
              });
          }
        }
      );
    };

    onMounted(() => {
      createProduct()
        .then((response) => {
          entrepreneur_stores.value = response.data.data.entrepreneur_stores;
          entrepreneur_product_types.value = response.data.data.entrepreneur_product_types;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
      checkPage("entrepreneur-product-type-create");
      setCurrentPageBreadcrumbs("เพิ่มประเภทสินค้า", [
        "ส่งเสริมการท่องเที่ยว",
        "ผู้ประกอบการ",
      ]);
    });

    return {
      product,
      submitButton,
      onSubmitProduct,
      isLoading,
      color,
      entrepreneur_stores,
      entrepreneur_product_types,
    };
  },
});
</script>
<style scoped>
</style>
